#root.bottom-space {
  padding-bottom: calc(60px + env(safe-area-inset-bottom));
}

body {
  background-color: #f4f7fb;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide {
  display: none !important;
}

/********** Text **********/

h1,
h2,
h3,
h4,
h5,
h6 {
  /* color: #12263F; */
  color: #0b203a;
  font-weight: 600;
}

a {
  transition: color 0.05s ease-in-out;
}

a.text-muted:hover {
  color: #494f54;
}

/* strong {
  color: hsl(233deg 49% 26%);
  font-weight: 600;
} */

.text-black {
  color: #000;
}

.text-magenta {
  color: #FF4E5E;
}

.text-magenta svg {
  fill: #FF4E5E;
}

.text-green {
  color: #10B981;
}

.text-green svg {
  color: #10B981;
}

.dark-link {
  transition: all 0.1s ease-in-out;
}

.dark-link:hover {
  color: hsl(213deg 56% 16% / 58%) !important;
}

.lead {
  font-size: 18px;
}

.lead-xl {
  font-size: 24px;
}

.font-weight-black {
  font-weight: 900 !important;
}

.font-weight-extrabold {
  font-weight: 700 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.extra-small {
  font-size: 10px;
}

.medium {
  font-size: 14px;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.rhap_time {
  font-size: 14px !important;
}

/********** Backgrounds **********/

.bg-white-500 {
  background-color: hsl(0deg 0% 100% / 20%);
}

.bg-light-red {
  background-color: rgba(220, 53, 69, 0.1);
}

.bg-light-blue {
  background-color: rgba(97, 188, 253, 0.1);
}

.bg-light-green {
  background-color: rgba(97, 253, 136, 0.1);
}

.bg-green {
  background: #2eb87a;
}

.bg-black {
  background-color: #000;
}

.bg-light-purple {
  background-color: #6772E5;
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.ril__caption,
.ril__toolbar {
  background-color: transparent !important;
}

/********** Borders **********/

.rounded-sm {
  border-radius: 0.4em !important;
}

.rounded {
  border-radius: 0.54em !important;
}

.rounded-top {
  border-radius: .6em .6em 0 0 !important;
}

.rounded-bottom {
  border-radius: 0 0 0.54em 0.54em !important;
}

.rounded-right {
  border-radius: 0 0.54em 0.54em 0 !important;
}

.rounded-left {
  border-radius: 0.54em 0 0 0.54em !important;
}

.rounded-bottom-right {
  border-radius: 0 0 0.54em 0;
}

.rounded-bottom-left {
  border-radius: 0 0 0 0.54em;
}

.rounded-xl {
  border-radius: 1.4em !important;
}

.rounded-xl-top {
  border-radius: 1.4em 1.4em 0 0 !important;
}

.rounded-xl-bottom {
  border-radius: 0 0 1.4em 1.4em !important;
}

.rounded-lg {
  border-radius: 0.8em !important;
}

.rounded-lg-top {
  border-radius: 0.8em 0.8em 0 0 !important;
}

.rounded-lg-bottom {
  border-radius: 0 0 0.8em 0.8em !important;
}

.rounded-lg-bottom-left {
  border-radius: 0 0 0 0.8em !important;
}

.rounded-lg-bottom-right {
  border-radius: 0 0 0.8em 0 !important;
}

.border-dashed {
  border: 2px dashed hsl(215deg 9% 80%);
}

.border-light {
  border: 1px solid #e6e6e6 !important;
}

.border-light-top {
  border-top: 1px solid #e6e6e6 !important;
}

.border-light-left {
  border-left: 1px solid #e6e6e6 !important;
}

.border-light-right {
  border-right: 1px solid #e6e6e6 !important;
}

.border-light-bottom {
  border-bottom: 1px solid #e6e6e6 !important;
}

.border-bottom {
  border-bottom: #e6e6e6 1px solid !important;
}

.post.border-bottom-md,
.promotion.border-bottom-md {
  border-bottom: #e5e5e5 1px solid !important;
}

.border-bottom-md {
  border-bottom: #e5e5e5 1px solid !important;
}

.border-feed {
  border-radius: 2px;
  border-left: 2px solid #e6e6e6;
}

.border-left {
  border-left: 3px solid #0e1d30;
}

.shadow-blue {
  box-shadow: 0 0 0 2px #318aff;
  border: 1px solid #FFF;
}

.border-blue {
  border: 2px solid #318aff;
}

hr.light {
  border-color: #ededed;
}

/********** Colors **********/

.badge.badge-primary {
  background-color: #318aff;
}

.badge {
  padding-top: 4px;
}

.text-secondary {
  color: #8D95A0 !important;
}

a.text-secondary:focus,
a.text-secondary:hover,
button.text-secondary:hover {
  color: #494f54 !important;
}

/********** Positioning **********/

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.top-50 {
  top: 50%;
  transform: translateY(-50%);
}

.top-100 {
  top: 100%;
}

.left-50 {
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center {
  top: 44%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/********** Spacing **********/

.pb-2,
.py-2 {
  padding-bottom: .5rem !important;
}

.pt-2,
.py-2 {
  padding-top: .5rem !important;
}

/********** Headless UI **********/

.right-0 {
  right: 0;
}

.w-56 {
  width: 14rem !important;
}

.w-40 {
  width: 12rem !important;
}

.w-30 {
  width: 10rem !important;
}

.transition-color {
  transition: color 0.05s ease-in-out;
}

[id*="headlessui-menu-item"] {
  line-height: 1.25rem;
}

.headless-ui-dropdown {
  z-index: 1;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.headless-ui-dropdown a:hover {
  background: #f4f5f6 !important;
  color: #343a40 !important;
}

.headless-ui-dropdown button:hover {
  background: #f4f5f6 !important;
  color: #343a40 !important;
}

/********** Shadows **********/

/* .shadow-sm {
  box-shadow: 0 1px 3px rgba(99, 99, 99, 0.2) !important;
} */

.shadow-lg {
  box-shadow: 0 7px 1.3em rgba(99, 99, 99, 0.225) !important;
}

.shadow-md {
  box-shadow: 0 7px 1.3em rgba(99, 99, 99, 0.225) !important;
}

.card-shadow {
  /* box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03); */
  /* box-shadow: 0 1px 2px rgba(
    0, 0, 0, 0.15); */
  /* box-shadow: 0 0 6px rgba(0, 10, 25, 0.09); */
  box-shadow: 0 2px 4px rgba(0, 10, 25, .09);
  border: none;
}

.shadow-none {
  box-shadow: none !important;
}

/********** Icons **********/

.tracker-logo {
  color: #FFF;
  transition: all 0.1s ease-in-out;
}

.tracker-logo:hover {
  color: hsl(0deg 0% 100% / 58%);
}

.tracker-logo-dark {
  color: #12263F;
  transition: all 0.1s ease-in-out;
}

.tracker-logo-dark:hover {
  color: hsl(213deg 56% 16% / 58%);
}

.sidebar-icon {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

[class*="img-icon"] {
  object-fit: cover;
  /* image-rendering: pixelated; */
}

.img-icon-xxl {
  width: 140px;
  height: 140px;
}

.img-icon-xl {
  width: 60px;
  height: 60px;
}

.img-icon-lg {
  width: 46px;
  height: 46px;
}

.img-icon {
  width: 40px;
  height: 40px;
}

.img-icon-sm {
  width: 38px;
  height: 38px;
}

.img-icon-xs {
  width: 28px;
  height: 28px;
}

.img-icon-xxs {
  width: 22px;
  height: 22px;
}

.comment-img {
  margin-top: 3px;
}

.slideshow-arrow {
  position: absolute;
  top: 50%;
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 22px;
  padding: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background: #FFF;
}

.slideshow-arrow.right-arrow {
  right: -45px;
}

.slideshow-arrow.left-arrow {
  left: -45px;
}

/********** Buttons **********/

.btn {
  box-shadow: 0 2px 3px rgba(50, 50, 93, .11);
  border-radius: .5em;
}

.custom-login-btn {
  transition: all 0.4s ease;
}

.custom-login-btn:hover {
  background-color: hsl(0deg 0% 100% / 50%);
}

[class*="btn-outline"] {
  box-shadow: none !important;
}

.btn-white {
  background-color: #FFF;
}

.btn-light-green {
  background-color: #3ECF8E;
}

.btn-light-purple {
  background-color: #6772E5;
}

.btn-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.btn-green {
  background: #2eb87a;
  color: #FFF;
}

.btn-lava {
  background: hsl(7deg 87% 63%);
  color: #FFF;
}

.btn-purple {
  background-color: hsl(233deg 49% 19%);
}

.header-admin-btn {
  background: #FFF;
  min-width: 76px;
}

.takeout-card-img {
  position: relative;
}

.takeout-card-share {
  position: absolute;
  width: 34px;
  height: 34px;
  bottom: 10px;
  border-radius: 50%;
  right: 10px;
  background: #fff;
  padding-left: 8px;
  padding-top: 3px;
  box-shadow: 0 0 10px #3c3c3c6b;
  color: #000;
}

/********** Cursor **********/

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-text:hover {
  cursor: text !important;
}

.cursor-normal:hover {
  cursor: default !important;
}

/********** Cards **********/

.card {
  border-radius: 0.8em;
}

.card-body {
  padding: 16px;
}

.job-listing {
  transition: all 0.05s ease;
  background-color: #FFF;
}

.job-listing:hover {
  background-color: #f9f9f9;
}

.list-style-none {
  list-style: none;
}

/********** Loader **********/

.custom-loader {
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
  width: 35px;
  height: 35px;
}

.loading-container {
  margin-top: 14.5rem;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/********** Filepond **********/

.filepond-circle {
  font-size: 14px !important;
  box-shadow: 0 1px 4px rgba(50, 50, 93, .13);
  border-radius: .6em;
  width: 147px;
}

.filepond--credits {
  display: none;
}

.filepond-circle .filepond--panel-root {
  background-color: #fff;
  border-radius: .6em;
}

.filepond-normal {
  background-color: #edf0f4;
}

.filepond-normal .filepond--item {
  width: calc(50% - 0.5em);
  border-radius: 0.8em;
}

.wide.filepond-normal .filepond--item {
  width: calc(100% - 0.5em) !important;
  border-radius: 0.8em;
}

.filepond-normal .filepond--panel-root {
  background-color: #edf0f4;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 0.8em;
}

.filepond--root .filepond--drip,
.filepond--root .filepond--panel-root {
  border-radius: 1em;
}

.filepond-blank .filepond--panel-root {
  border: none !important;
  background-color: #FFF !important;
}

/********** Dropdown **********/

/* .dropdown .dropdown-menu {
  opacity: 0 !important;
  padding: .4rem 0 !important;
} */

.post-dropdown {
  top: 30px !important;
  left: -136px !important;
}

.dropdown.show .dropdown-menu {
  /* left: -138px !important;
  top: 27px !important; */
  border-radius: .5em;
  pointer-events: auto !important;
  opacity: 1 !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid #dee2e6d2;
}

.main-nav.dropdown-menu {
  left: -118px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #374151 !important;
  text-decoration: none;
  background-color: hsl(210deg 10% 82%);
}

.dropdown-item {
  font-weight: 500;
  font-size: 15px;
  padding: .25rem 1.2rem;
  color: #374151;
}

.select__control {
  border-radius: 0.54em !important;
  border: 1px solid #dee2e6 !important;
}

/********** Tooltips **********/

div[role*="tooltip"] {
  z-index: 0;
  border: none;
  border-radius: 0.6em;
  /* box-shadow: 0 1px 3px rgba(99, 99, 99, 0.2) !important; */
  box-shadow: 0 2px 4px rgba(0, 10, 25, .09) !important;
}

.popover-header {
  border-radius: .6em .6em 0 0;
}

/********** Toasts **********/

.toast {
  border: none;
  border-radius: .6em;
  max-width: 220px;
}

.toast.success {
  background-color: rgba(0, 184, 156, 0.85);
  color: #fff;
  font-weight: bold;
}

.toast .toast-body {
  padding: .50rem .75rem;
}

/********** Forms **********/

.form-control-xl {
  font-size: 2.5rem !important;
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 40px;
  line-height: 48px;
  border-radius: .3rem;
}

.post-edit-body {
  min-height: 150px;
}

.placeholder-light::placeholder {
  color: #c1c7cd !important;
}

.form-control {
  border-radius: 0.4rem;
  border: 1px solid #D1D5DB;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::placeholder {
  color: #9197a3;
  font-weight: 400;
}

.form-control:focus::placeholder {
  color: #9197a3;
}

.comment-input {
  box-shadow: none;
  font-size: 15px;
  border-radius: 16px;
  resize: none;
  background-color: #f0f2f5;
  border: none;
  color: #8b8d90;
  min-height: 37px;
  line-height: 25px;
  border-radius: 0.8em !important;
}

.comment-input:focus {
  background-color: #f0f2f5;
}

.comment-input::placeholder {
  font-size: 15px;
}

/* Style slate placeholder */
.slate-text-editor div span span span {
  opacity: 1 !important;
  vertical-align: initial !important;
  color: #65686B;
}

/********** Modals **********/

.modal-content {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  border-radius: .8em !important;
  border: none;
}

.custom-modal .modal-content {
  background: transparent;
  border-radius: 0 !important;
}

.full-width-modal {
  width: 85%;
  max-width: none !important;
}

.custom-modal-backdrop {
  opacity: .8 !important;
}

.react-transform-component {
  margin: 0 auto !important;
}

.react-images__blanket {
  background-color: #000 !important;
}

.modal-backdrop.show {
  opacity: .4;
}

.emoji-mart-popover.popover {
  max-width: max-content;
}

.emoji-mart-popover {
  z-index: 1050 !important;
}

.emoji-mart {
  border: none !important;
}

.emoji-mart-emoji span:hover {
  cursor: pointer !important;
}

.emoji-mart-bar {
  display: none;
}

.modal.slideover .modal-dialog {
  margin: 0 0 0 auto;
  width: calc(100vw - 90px);
}

.modal.slideover .modal-dialog .modal-content {
  height: 100vh;
  overflow-y: scroll;
  border-radius: 0 !important;
}

/********** Post **********/

.post-header {
  margin-bottom: 0.7rem !important;
}

.post-main-image {
  max-height: 580px;
  min-height: 200px;
  width: 100%;
  object-fit: contain;
}

.post-main-image.short {
  max-height: 400px;
  object-fit: cover;
}

.post-main-image.full {
  max-height: none;
  min-height: 200px;
  width: 100%;
}

.post-media-col.border-right {
  border-right: 1px solid #FFF !important;
}

.post-media-col.border-top {
  border-top: 1px solid #FFF !important;
}

.post-media-col {
  position: relative;
}

.post-media {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ryt-lite:before {
  background-image: none !important;
}

.post-media img,
.post-media video {
  object-fit: cover;
}

.post-media-medium {
  max-height: 300px;
}

.post-print-link {
  background: #000;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 0.3em #00000091 !important;
  /* transition: all 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96); */
}

.post-print-link:hover {
  /* padding-left: 2rem !important; */
  /* padding-right: 2rem !important; */
}

.post-media.overlay {
  object-fit: cover;
  filter: brightness(70%);
  width: 100%;
}

.post-media-text {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}

.post-name {
  font-size: 15px;
  line-height: 18px;
}

.post-date {
  font-size: 12px;
  line-height: 18px;
}

.post-body-container {
  line-height: 22px;
}

.post-title {
  color: #1a1a1a !important;
}

.job-post-body,
.full-post-body,
.post-body,
.comment-body {
  white-space: pre-line;
  word-break: break-word;
}

.post-body,
.comment-body {
  font-size: 15px;
  line-height: 22px;
}

.full-post-body {
  font-size: 18px;
}

.comment-container {
  padding-top: 2px;
  padding-bottom: 2px;
}

.post .post-name,
.comment .comment-name {
  color: #12263f;
}

.post-new-comment-col {
  padding: 15px;
}

.comment-bg {
  position: relative;
  background-color: #f2f3f5;
  border-radius: 16px;
}

.comment-likes {
  position: absolute;
  bottom: 7px;
  right: -14px;
  background-color: #fff;
  color: #FF4E5E;
  fill: #FF4E5E;
  stroke: #FF4E5E;
  box-shadow: -1px 1px 3px 1px #dfdfdf;
}

.post-media-container div {
  border: none;
}

.post-donate-btn {
  width: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none !important;
}

.post-info-btn .icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.post-info-btn:hover .icon-container {
  background-color: #f5f5f5;
  transition: all 0.2s ease-in-out;
}

.post-info-btn.like-btn:hover .icon-container {
  background-color: #FFE9EB;
  color: #ff4e5e;
}

.post-info-btn.comment-btn:hover {
  color: #007bff;
}

.post-info-btn.comment-btn:hover .icon-container {
  background-color: #DBECFF;
}

.post-info-btn.tip-btn:hover {
  color: #2eb87a;
}

.post-info-btn.tip-btn:hover .icon-container {
  background-color: #E1F5EC;
}

.comment-like-btn.liked,
.comment-like-btn.initial-like {
  color: #FF4E5E;
}

.like-btn:hover {
  cursor: pointer;
}

.like-btn.liked,
.like-btn.initial-like,
.like-btn.liked svg,
.like-btn.initial-like svg {
  color: #ff4e5e;
  fill: #ff4e5e;
  stroke: #ff4e5e;
}

.like-btn.liked svg {
  -webkit-animation: heart .5s cubic-bezier(0.17, 0.89, 0.32, 1.49);
  animation: heart .5s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

@keyframes heart {
  30% {
    transform: scale(0.5);
  }

  60% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.video {
  width: 100% !important;
  height: 100% !important;
}

video:focus {
  outline: none;
}

.ryt-lite>.lty-playbtn {
  z-index: 0 !important;
}

.group-header-yt-video.aspect-ratio-16\/9 {
  padding-bottom: 36.25% !important;
}

.aspect-ratio-16\/9 {
  position: inherit !important;
}

/********** Headers **********/

.main-landing-title-container {
  margin-top: 8rem;
}

.main-landing-tagline {
  font-size: 1.125rem;
}

h1.main-landing-title {
  top: 0;
  left: 0;
  /* font-size: 5rem; */
  line-height: 108%;
  letter-spacing: -0.02em;
}

h1.main-landing-title.base {
  color: hsl(230deg 12% 80%) !important;
}

h1.main-landing-title.overlay {
  color: #404040 !important;
  mix-blend-mode: color-burn;
}

.main-landing-header {
  overflow: hidden;
}

.main-landing-header .main-header-bg {
  background: url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/landing-header-1.jpg"), radial-gradient(at 15% 85%, hsla(199, 94%, 82%, 1) 0, transparent 32%), radial-gradient(at 66% 37%, hsla(196, 89%, 77%, 1) 0, transparent 49%), radial-gradient(at 75% 96%, hsla(200, 100%, 91%, 1) 0, transparent 30%), radial-gradient(at 23% 56%, hsla(18, 80%, 75%, 1) 0, transparent 34%), radial-gradient(at 10% 50%, hsla(34, 89%, 69%, 1) 0, transparent 82%), radial-gradient(at 7% 5%, hsla(0, 100%, 50%, 1) 0, transparent 56%), radial-gradient(at 81% 0%, hsla(50, 62%, 86%, 1) 0, transparent 39%);
  background-size: 100% 100%;
  position: absolute;
  width: 100vw;
  height: 450px;
  background-position: top;
  transform: skewY(-8.5deg);
  transform-origin: 0;
  top: 0;
}

.landing-header-submit {
  top: 0.25em;
  right: 0.5em;
  position: absolute;
}

.main-header-button {
  font-weight: 500;
  border: 2px solid;
  transition: all 0.1s ease-in-out;
}

.group-header-yt-video {
  padding-bottom: 56.25% !important;
}

.support-businesses-header {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/support-business-header.jpg");
  background-size: cover;
  background-position: center;
  text-shadow: 0 0 20px rgba(0.45, 0.31, 0.56, 0.5);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.festival-of-trees-header {
  background-image: url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/festival-of-trees-header-2.jpg");
  background-size: cover;
  background-position: center;
  text-shadow: 0 0 20px rgba(0.45, 0.31, 0.56, 0.5);
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); */
  border-radius: 0;
}

.job-board-header {
  /* background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, transparent 50%), url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/job-board-header.jpeg"); */
  background-image: url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/roseburg-aerial.jpeg");
  background-size: cover;
  background-position: center 56%;
  padding-bottom: 12vw;
  border-radius: 0;
  position: relative;
}

.job-board-header svg.wave {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100vw;
}

.job-board-header h1 {
  text-shadow: 1px 3px 6px rgb(45 45 45 / 40%);
}

.job-board-header .job-board-success {
  position: absolute;
  bottom: -50%;
  width: 100%;
}

.job-board-header.job-boarder-header-success {
  height: 37vh;
  min-height: 400px;
}

/* .bg-fwwr {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(88, 147, 28, 0.3) 100%);
} */

/********** Footer **********/

.main-landing-footer {
  /* background-color: #000; */
  /* background: linear-gradient(0deg, #040b15, #0a1838); */
}

/********** Images **********/

.screenshot-mockup {
  top: 0;
  left: 0;
}

.object-cover {
  object-fit: cover;
}

/********** Navigation **********/

.main-mobile-nav-item {
  color: #5B6F83;
  border-top: 1px solid transparent;
  width: 60px;
  height: 60px;
  /* transition: all 0.1s ease; */
}

.main-mobile-nav-alert {
  width: 15px;
  height: 15px;
  right: 11px;
  top: 6px;
  background: #ff3648;
}

.main-mobile-nav-item:hover {
  color: #5B6F83;
}

.main-mobile-nav-item.active {
  color: #007bff;
}

.main-mobile-nav-item.active span {
  font-weight: 600;
}

.main-page-nav .nav-overlay {
  z-index: 101;
  width: calc(100% - 30px);
  transition: all 0.3s cubic-bezier(0.42, 0, 0, 0.94);
  opacity: 0;
  top: 0;
}

.main-page-nav .nav-overlay.hide {
  opacity: 0;
  top: -250px;
}

.main-page-nav .nav-overlay.show {
  opacity: 1;
  top: 0;
}

.main-page-nav * {
  z-index: 100;
}

.main-page-nav-items a {
  letter-spacing: .2px;
  transition: all 0.1s ease-in-out;
}

.main-page-nav-items a:hover {
  color: hsl(0deg 0% 100% / 58%) !important;
}

.navbar {
  z-index: 1;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-collapse.collapse.show {
  height: 100vh;
}

img[class*="img-icon"].active {
  box-shadow: rgb(122, 177, 253) 0px 0px 0px 2px;
  border: 1px solid white;
}

.main-ui-nav-overlay-container.hidden {
  top: 120vh;
}

.main-ui-nav-overlay-container.show {
  top: 50px;
}

.main-ui-nav-overlay-container {
  transition: all 0.1s ease-in-out;
  display: flex;
  justify-content: center;
}

.main-ui-nav-overlay-container .main-ui-nav-overlay {
  /* background-color: #f4f7fb; */
  background-color: #FFF;
  border-radius: 1.4em 1.4em 0 0;
  /* box-shadow: 0px 0px 50px rgba(59, 59, 59, 0.8); */
  overflow: scroll;
}

.main-ui-nav-overlay-container,
.main-ui-nav-overlay {
  height: calc(100vh - 60px - env(safe-area-inset-bottom));
  z-index: 1;
}

.main-ui-nav-overlay-header {
  /* background-color: #f4f7fb; */
  background-color: #FFF;
  position: fixed;
  border-radius: 1.4em 1.4em 0 0;
}

/* .main-ui-nav-overlay-item:focus {
  background-color: #f4f5f6;
} */

.main-ui-nav-overlay-content {
  margin-top: 65px;
}

.main-ui-nav-overlay-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

.main-ui-nav-overlay-bg.hidden {
  background: transparent;
  opacity: 0;
  top: 100vh;
}

.main-ui-nav-overlay-bg.show {
  background: #000;
  opacity: 0.1;
  z-index: 1;
}

.main-ui-nav-overlay-bar {
  height: 4px;
  width: 74px;
  margin-top: -20px;
}

.main-ui-nav.navbar {
  box-shadow: 0 1px 4px rgba(0, 10, 25, .09);
}

.main-nav-item {
  padding-left: 10px;
  padding-top: 7px;
}

.main-nav-item,
.main-nav-item a {
  color: #676979 !important;
  transition: all .18s;
}

.main-nav-item.active,
.main-nav-item.active svg,
.main-nav-item.active a {
  color: #398fff !important;
}

.main-nav-item:hover,
.main-nav-item:hover a {
  color: #1877F2 !important;
}

.main-nav-search-input {
  position: relative;
  text-indent: 20px;
}

.main-nav-search-addon {
  position: absolute;
  top: 10px;
  left: 64px;
  z-index: 1000;
}

.main-nav-sales a {
  background-color: #318aff;
  padding: 10px 22px;
  transition: all .18s;
  border-radius: 25px;
}

.main-nav-sales a:hover {
  background-color: #FFF;
  box-shadow: 0 0 0 2px #318aff;
  color: #318aff !important;
}

.notification-btn {
  padding-left: 18px;
}

.notification-btn .notification-dot {
  width: 15px;
  height: 15px;
  background: #007bff;
  border-radius: 10px;
  position: absolute;
  top: 6px;
  left: 30px;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.notification-btn .notification-dot span {
  margin-top: -1px;
  display: block;
}

/********** Main Landing **********/

.connecting-border {
  position: absolute;
  height: 60px;
  width: 3px;
  top: -30px;
  left: 50vw;
  background: hsl(0deg 0% 65%);
}

svg.icon-bolt {
  width: 74px;
  height: 74px;
}

.icon-bolt circle.primary {
  fill: hsl(137deg 63% 57%);
}

.icon-bolt path.secondary {
  fill: hsl(137deg 63% 33%);
}

/********** Feed **********/

.tracker-feed-sidebar-nav {
  padding: 7px;
}

.tracker-feed-sidebar-nav .sidebar-nav-item {
  transition: all 0.1s;
}

.tracker-feed-sidebar-nav .sidebar-nav-item.highlighted {
  background: hsl(214deg 10% 96%);
}

.tracker-feed-sidebar-nav div:hover .sidebar-nav-item.highlighted:not(:hover) {
  background-color: transparent;
}

.tracker-feed-sidebar-nav .sidebar-nav-item:hover {
  background: hsl(214deg 10% 96%);
}

.tracker-feed-sidebar-nav .sidebar-nav-item.text-muted:hover {
  background: hsl(214deg 10% 89%);
}

.tracker-groups-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* .tracker-groups-container:after {
  bottom: 0;
  right: 0;
  height: 61%;
  width: 17%;
  content: " ";
  position: absolute;
  background: transparent;
  background: -moz-linear-gradient(270deg, rgb(255, 255, 255) 0%, transparent 100%);
  background: -webkit-linear-gradient(270deg, rgb(255, 255, 255) 0%, transparent 100%);
  background: linear-gradient(270deg, rgb(255, 255, 255) 0%, transparent 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
} */

.tracker-groups-container .tracker-groups-item {
  display: inline-block;
}

.tracker-groups-item a {
  /* box-shadow: 0 0px 0 2px rgba(0, 0, 0, 0.07) inset; */
  border-radius: 0.7em !important;
}

.tracker-groups-item.highlighted a {
  background-color: hsl(208deg 41% 95%);
  /* box-shadow: 0 0px 0 2px rgba(49, 138, 255, .71) inset; */
}

/* .tracker-groups-item a:hover {
  background-color: hsl(208deg 41% 97%);
} */

/* .tracker-groups-item a img {
  border-radius: 0.54em 0 0 0.54em !important;
} */

.tracker-header-img,
.tracker-header-img-sm {
  object-fit: cover;
  width: 100%;
}

.tracker-header-img {
  height: 224px;
}

.tracker-header-img-sm {
  height: 166px;
}

.tracker-header-info-overlay {
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 95%, rgba(0, 0, 0, 0.2) 120%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 95%, rgba(0, 0, 0, 0.2) 120%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 95%, rgba(0, 0, 0, 0.2) 120%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#ffffff", GradientType=1);
}

.new-post-editor-container {
  min-height: 100%;
  padding-top: 88px;
}

.new-post-editor-container.full-height {
  min-height: calc(100vh - 7.25em);
}

.new-post-button {
  background-color: #f0f2f5;
  border: none;
  color: #8b8d90;
}

.new-post-image-btn {
  color: #27cc5b;
}

.new-post-twitter-btn,
.new-post-twitter-btn:hover {
  color: #1da2ef !important;
}

.new-post-youtube-btn,
.new-post-youtube-btn:hover {
  color: #fd0015 !important;
}

.new-post-button:hover {
  background-color: #e4e6e9;
  color: #8b8d90;
}

.new-post-button:hover {
  background-color: #e4e6e9;
  color: #8b8d90;
}

.new-post-button:hover {
  background-color: #e4e6e9;
  color: #8b8d90;
}

.new-post-button,
.new-post-image {
  box-shadow: none;
}

.new-post-button:focus,
.new-post-image:focus {
  box-shadow: none;
}

.new-post-image {
  box-shadow: none;
  background-color: #f2f2f4;
}

.new-post-profile,
.new-post-image {
  width: 42px;
  height: 42px;
}

/********** Profile **********/

.profile-thumbnail-img {
  position: relative;
  top: -56px;
  left: -6px;
  width: 78px;
  height: 78px;
  border: 4px #fff solid;
}

.profile-header-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.profile-right-bar {
  height: calc(100vh - 100px);
  overflow-y: hidden;
  border: 1px solid #edf2f9;
}

.profile-right-bar .profile-right-bar-content {
  height: calc(100vh - 158px);
  overflow-y: scroll;
}

.profile-posts-search {
  width: 200px;
  box-shadow: 0 1px 5px #e3e3e4;
  border-radius: .6em;
}

.profile-posts-search input {
  box-shadow: none;
}

.profile-posts-search input:focus {
  box-shadow: none;
}

.profile-posts-search .input-group-append span {
  background-color: #fff;
  border-radius: .6em;
  border: none;
  border-left: 2px solid #dee2e6;
}

.profile-search-bar {
  height: 3rem;
  padding-left: calc(24px + 1rem);
}

.tab-item {
  text-align: center;
}

.tab-highlighted {
  border-bottom: 2px solid #3b82f6 !important;
  color: #3B82F6 !important;
}

.tab-highlighted:hover {
  border-bottom: 2px solid #3b82f6 !important;
  color: #3B82F6 !important;
}

.profile-right-bar .tab-content {
  background-color: #fafafa;
}

.profile-right-bar .refresh-btn i,
.dropdown i {
  color: #495057;
}

.nav-tabs .nav-item {
  text-align: center;
  color: #67717A;
  font-weight: bold;
  padding: 16px;
  width: 33.333%;
}

.nav-tabs .nav-item[class$="nav-link"]:hover {
  padding-top: 17px;
  border: none;
  border-radius: 0.6em 0.6em 0 0 !important;
  box-shadow: rgb(152, 152, 152) 0px -2px 0px inset;
  color: rgb(36, 41, 46);
  background-color: rgb(250, 250, 250);
}

.nav-tabs .nav-item.active {
  border-radius: 0.6em 0.6em 0 0 !important;
  box-shadow: rgb(36, 41, 46) 0px -2px 0px inset;
  color: #24293E;
}

/********** Admin **********/

.admin-container {
  padding-top: 64px;
}

.admin-content {
  padding-left: 335px;
  margin: 0 !important;
}

.admin-content .col {
  min-height: calc(100vh - 10rem);
}

.admin-sidebar {
  width: 320px;
  position: fixed;
  overflow-y: scroll;
  background-color: #f5f7fa;
  /* Full Height - Nav Height - User Profile Height */
  height: calc(100vh - 64px - 86px);
}

.admin-nav {
  height: 64px;
  background-color: #FFF;
  padding-left: calc(1.5rem - 9.8px);
}

.admin-nav,
.admin-sidebar {
  border-right: 1px solid #e6e6e6 !important;
  border-bottom: 1px solid #e6e6e6 !important;
}

.admin-nav-item,
.admin-sidebar-user {
  transition: all .03s ease-in-out;
}

.admin-nav-item {
  display: flex;
  font-size: 15px;
  max-height: 47px;
  color: #212529;
  user-select: none;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 0.54em;
  text-decoration: none !important;
}

.admin-sidebar-user {
  width: 320px;
  background-color: #f5f7fa;
}

.admin-nav-container:hover .admin-nav-item.active {
  background-color: transparent;
}

.admin-nav-item:hover,
.admin-sidebar-user:hover {
  color: #212529;
  cursor: pointer;
  background-color: #e2e8ee !important;
}

.admin-nav-item.active {
  font-weight: 500;
  background-color: #e2e8ee;
}

.admin-post-preview:hover {
  background: hsl(0deg 0% 97%);
}

.media-view img {
  transition: all 0.3s ease;
}

.media-view:hover img,
.media-view.hovered img {
  filter: brightness(80%);
}

.media-view-btn {
  background-color: #000;
}

.media-view-actions {
  z-index: 10;
}

.suggested-preview {
  width: 33.33%;
}

/********** Advertise **********/

.advertise-header {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/sales-page-header.jpg");
  background-size: cover;
  background-position: center;
  text-shadow: 0 0 20px rgba(0.45, 0.31, 0.56, 0.5);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.roseburg-advertise-header {
  height: 40vh;
  min-height: 578px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0) 100%), url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/roseburg-aerial.jpeg");
  background-size: cover;
  background-position: center 48%;
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.6); */
  text-shadow: 0 0 10px rgba(0.45, 0.31, 0.56, 0.5);
  border-radius: 0;
}

.hawaii-advertise-header {
  min-height: 40vh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 50%), url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/sales/hawaii-tracker-sales-bg.jpeg");
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  border-radius: 0;
}

.hawaii-advertise-header h1 {
  text-shadow: 0 0 20px rgba(0.45, 0.31, 0.56, 0.8);
  letter-spacing: 1px;
}

.hawaii-advertise-header .tagline {
  text-shadow: 0 0 5px rgba(0.45, 0.31, 0.56, 1);
}

.blue-border-top {
  position: absolute;
  display: inline-block;
  width: 80px;
  height: 10px;
  background-color: #007bff;
  left: 20px;
  top: 20px;
}

/* .advertise-highlight-card {
  transform: scale(1.04);
  border: 3px solid #1c74fb;
} */

.advertise-package-benefit-list,
.advertise-package-non-benefit-list {
  list-style: none;
}

.advertise-package-benefit-list li,
.advertise-package-non-benefit-list li {
  margin-bottom: .5rem;
  padding: 0 0 0 30px;
  background-position: center;
}

.advertise-package-benefit-list li {
  background: url(https://jointracker-assets.s3-us-west-2.amazonaws.com/img/icons/check.svg) no-repeat;
}

.advertise-package-non-benefit-list li {
  background: url(https://jointracker-assets.s3-us-west-2.amazonaws.com/img/icons/x.svg) no-repeat;
}

.example-sales-img {
  /* height: 200px;
  object-fit: contain; */
  transition: all 0.5s;
}

.example-sales-img:hover {
  transform: scale(1.025);
}

.ad-info-card {
  height: 325px;
}

.advertise-payment-input {
  padding-top: 8px;
  padding-right: 3px;
}

.advertise-payment-checkout-btn {
  background-color: #27ae60;
  border-color: #27ae60;
}

.advertise-payment-checkout-btn:hover {
  background-color: #209450;
  border-color: #209450;
}

.advertise-payment-order-details {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.3) 100%), url("https://jointracker-assets.s3-us-west-2.amazonaws.com/img/payment-checkout-graphic.jpg");
  background-size: cover;
}

.ad-icon-user-group,
.ad-icon-security-check,
.ad-icon-announcement {
  width: 50px;
  height: 50px;
}

.ad-icon-user-group {
  background: hsl(153 60% 90% / 1);
}

.ad-icon-user-group .primary {
  fill: hsl(153 60% 45% / 1);
}

.ad-icon-user-group .secondary {
  fill: hsl(153 60% 29% / 1);
}

.ad-icon-security-check {
  background: hsl(211 100% 93% / 1);
}

.ad-icon-security-check .primary {
  fill: hsl(211 97% 66% / 1);
}

.ad-icon-security-check .secondary {
  fill: hsl(211 100% 29% / 1);
}

.ad-icon-announcement {
  background: hsl(58 68% 90% / 1);
}

.ad-icon-announcement .primary {
  fill: hsl(48 84% 51% / 1);
}

.ad-icon-announcement .secondary {
  fill: hsl(48 84% 30% / 1);
}

.sales-card {
  width: 100%;
  max-width: 40rem;
}

/********** Embeds **********/

.twitter-tweet {
  margin: 0 !important;
}

/********** Misc **********/

.rotated-bg {
  position: relative;
  background-color: #f9fbfd;
}

.rotated-bg::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 80%;
  top: -150px;
  -webkit-transform: skewY(-3.5deg);
  transform: skewY(-3.5deg);
  background-color: #f9fbfd;
}

/********** Containers **********/

.container {
  max-width: 1340px;
}

.container-xxl {
  max-width: 1640px;
}

/********** Media Queries **********/

@media all and (min-width: 1200px) {
  .ad-info-card-footer {
    height: 60rem;
  }

  .main-landing-title {
    font-size: 5.5rem;
  }
}

@media all and (max-width: 1000px) {
  .main-page-header {
    background: none;
  }

  .advertise-payment-order-details {
    background-position: 0;
    border-radius: 0 !important;
  }
}

@media all and (min-width: 1000px) {
  .rounded-lg-lg {
    border-radius: 0.8em !important;
  }
}

@media all and (min-width: 1050px) {
  .screenshot-mockup {
    top: 0;
    left: -45px;
  }
}

@media (max-width: 991px) {
  .admin-content .col {
    min-height: calc(100vh - 6rem);
  }

  .admin-nav {
    background-color: #f5f7fa;
    padding-left: calc(1rem - 9.8px);
  }

  .admin-content {
    padding-left: 0;
    margin: 0 !important;
  }

  .tracker-feed-sidebar-nav .sidebar-nav-item.highlighted {
    background: #e0e3e6;
  }

  .tracker-feed-sidebar-nav div:hover .sidebar-nav-item.highlighted:not(:hover) {
    background-color: transparent;
  }

  .tracker-feed-sidebar-nav .sidebar-nav-item:hover {
    background: #e0e3e6;
  }

  .tracker-feed-sidebar-nav .sidebar-nav-item.text-muted:hover {
    background: #e0e3e6;
  }

  .sidebar-nav-item a,
  a.sidebar-nav-item {
    color: #6c757d !important;
  }

  .sidebar-nav-item:hover a,
  a.sidebar-nav-item:hover {
    color: #6c757d !important;
  }

  .tracker-feed-sidebar-nav {
    padding: 0;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .container {
    max-width: 100% !important;
  }

  .tracker-header-img {
    max-height: 140px;
  }

  .twitter-tweet {
    width: 373px !important;
  }

  .comment-input {
    font-size: 16px;
  }

  .comment-input-fixed-height {
    padding-top: 5px;
  }

  .comment-input::placeholder {
    padding-top: 1px;
  }

  .landing-header-submit {
    top: 0.4em;
    right: 0.2em;
    position: absolute;
  }
}

@media (min-width: 991px) {
  .border-md-none {
    border: none !important;
  }

  .display-md-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .twitter-tweet {
    width: 457px !important;
  }
}

@media (max-width: 767px) {
  .full-post-body {
    font-size: 16px;
  }

  .post-main-image {
    max-height: 380px;
    min-height: 200px;
    width: 100%;
    object-fit: contain;
  }

  .post-main-image.full {
    min-height: 200px;
    width: 100%;
  }

  .new-post-editor-container {
    min-height: calc(100vh - 5em) !important;
  }

  .lead-xl {
    font-size: 18px;
  }

  .screenshot-mockup-container {
    overflow-x: scroll;
  }

  .main-ui-nav {
    box-shadow: 1px 1px #dee2e6 inset !important;
  }

  .main-landing-title {
    /* font-size: 5rem !important; */
  }

  .main-landing-title-container {
    margin-top: 8rem;
  }

  .main-landing-tagline {
    font-size: 18px !important;
  }

  .react-player {
    height: 300px !important;
  }

  .border-light {
    border: 1px solid #e6e6e6 !important;
  }

  /* .tracker-groups-item:last-child {
    margin-right: 30%;
  } */

  .hawaii-advertise-header .tagline {
    font-size: 16px;
  }

  .main-nav--hidden {
    top: -50px !important;
    opacity: 0;
  }

  .navbar-nav {
    height: 100vh;
    overflow: scroll;
  }

  .main-nav-item {
    width: 47.0645%;
    font-weight: 500;
    margin: 0 .5rem 0 0;
    padding: 0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 0.54em !important;
  }

  .main-nav-item.nav-link {
    margin: 0 auto 1rem 0;
  }

  .main-nav-item a {
    display: block;
    padding: .5rem;
    text-decoration: none;
  }

  .admin-sidebar {
    height: min-content;
    width: 100%;
  }

  .full-width-modal {
    width: 97%;
  }

  .post-image-medium {
    height: 32vw;
  }

  .post-image-large {
    height: 33vw;
  }

  .twitter-tweet {
    width: 288px !important;
  }

  .main-ui-nav.navbar {
    box-shadow: none;
    border-bottom: 1px solid #f5f5f5;
  }

  .filepond-normal .filepond--item {
    width: calc(100% - .5em);
  }

  .screenshot-mockup {
    top: 0;
    left: 45px;
  }
}

@media (min-width: 768px) {
  #root.top-space {
    padding-top: 64px;
  }

  .bg-md-glass {
    background: linear-gradient(-185deg, rgb(255 255 255 / 65%) -5%, transparent 80%) !important;
  }

  .border-sm-none {
    border: none !important;
  }

  .shadow-md-md {
    box-shadow: 0 7px 1.3em rgba(99, 99, 99, 0.225) !important;
  }

  .main-ui-nav.navbar {
    height: 64px;
  }

  .screenshot-mockup-container {
    margin-top: 4.2rem !important;
  }

  .shadow-md-0 {
    box-shadow: none !important;
  }

  .card-md-shadow {
    box-shadow: 0 2px 4px rgba(0, 10, 25, .09);
    /* border: none; */
  }

  .border-md-0 {
    border: none !important;
  }

  .post.border-bottom-md,
  .promotion.border-bottom-md {
    border: none !important;
  }

  .tracker-sidebar {
    position: -webkit-sticky;
    position: sticky;
    height: 100vh;
    overflow: hidden auto;
    top: 64px;
    padding-bottom: 6rem;
  }

  /* .tracker-sidebar-left {
    padding-left: 7px;
  } */

  .tracker-sidebar::-webkit-scrollbar {
    display: none;
  }

  .main-nav-item a {
    font-weight: 500;
  }

  .notification-btn {
    width: auto !important;
    padding-left: 0;
    margin-left: 12px !important;
  }

  .notification-btn .main-nav-item {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .notification-btn .notification-dot {
    top: -3px;
    left: 22px;
  }

  .rounded-md {
    border-radius: .8em !important;
  }

  .rounded-top-md {
    border-radius: .8em .8em 0 0 !important;
  }

  .rounded-pill-md {
    border-radius: 50rem !important;
  }
}

@media (max-width: 575px) {
  .suggested-preview {
    width: 50%;
  }

  .group-header-yt-video.aspect-ratio-16\/9 {
    padding-bottom: 55% !important;
  }
}

@media (max-width: 400px) {
  .main-landing-title {
    font-size: 3rem;
  }

  .main-landing-tagline {
    font-size: 16px !important;
  }
}

/* IOS Support */
/* @supports (-webkit-touch-callout: none) {
  .main-ui-nav-overlay-container, .main-ui-nav-overlay {
    height: calc(100vh - 67px);
  }
} */
